<template>
    <div>
        <!-- MODALS ================================================== -->
        <div class="modal" id="modalUsers" tabindex="-1" role="dialog" aria-hidden="true">
            <div class="modal-dialog modal-dialog-centered" role="document">
                <div class="modal-content">
                    <div class="modal-card card">
                        <div class="card-header">
                            <!-- Title -->
                            <h4 class="card-header-title" id="exampleModalCenterTitle">
                                Tambah akun users
                            </h4>
                            <!-- Close -->
                            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <form @submit.prevent="submitForm" enctype="multipart/form-data">
                            <div class="card-body">
                                <div class="form-group">
                                    <label>FullName <span class="text-primary">*</span></label>
                                    <input type="text" v-model="form.fullname" value="" class="form-control" required>
                                </div>
                                <div class="form-group">
                                    <label>username <span class="text-primary">*</span></label>
                                    <input type="text" v-model="form.username" value="" class="form-control" required>
                                </div>
                                <div class="form-group">
                                    <label>password <span class="text-primary">*</span></label>
                                    <input type="password" v-model="form.password" value="" class="form-control" required>
                                </div>
                                <div class="form-group">
                                    <label>Roles<span class="text-primary">*</span></label>
                                    <select class="form-control custom-select" v-model="form.roles" required>
                                        <option value="" disabled>Pilih</option>
                                        <option value="administrator">administrator</option>
                                        <option value="siswa">Siswa</option>
                                    </select>
                                </div>
                            </div>
                            <div class="card-footer border-top-0">
                                <ul class="pagination mb-0"></ul>
                                <div class="d-flex justify-content-end">
                                    <button type="button" class="btn btn-text text-muted" data-dismiss="modal">Batal & Tutup</button>
                                    <button type="submit" class="btn btn-primary">Simpan & Buat Akun Users</button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
        <!-- MAIN CONTENT ================================================== -->
        <div class="row justify-content-center">
            <div class="col-12 col-lg-12 col-xl-12">
                <div class="d-block pt-3 mb-4">
                    <div class="row align-items-center">
                        <div class="col">
                            <h1 class="header-title">
                                Data Users
                                <br><br>
                                <small class="form-text text-muted">
                                    Menu Ini Digunakan Untuk Mengelola akun users
                                </small>
                            </h1>
                        </div>
                        <div class="col-auto">
                            <a href="#" class="btn btn-primary ml-2" data-toggle="modal" data-target="#modalUsers">
                                <div class="d-flex align-items-center">
                                    <i class="fe fe-plus mr-2"></i>Tambah Users
                                </div>
                            </a>
                        </div>
                    </div>
                </div>
                <!-- CONTENT -->
                <div class="tab-content">
                    <div class="tab-pane show active" id="anggota-tab" role="tabpanel">
                        <div class="card" data-toggle="lists" data-options='{"valueNames": ["orders-number", "orders-nis", "orders-name", "orders-role", "orders-status"]}'>
                            <div class="card-header pb-3">
                                <!-- Search -->
                                <form>
                                    <div class="input-group input-group-flush">
                                        <div class="input-group-prepend">
                                            <span class="input-group-text">
                                                <i class="fe fe-search"></i>
                                            </span>
                                        </div>
                                        <input class="form-control search" type="search" placeholder="Search">
                                    </div>
                                </form>
                            </div>
                            <div class="table-responsive">
                                <table class="table table-sm table-nowrap card-table">
                                    <thead>
                                        <tr>
                                            <th>
                                                <a href="#" class="text-muted sort" data-sort="orders-number">
                                                    No
                                                </a>
                                            </th>
                                            <th>
                                                <a href="#" class="text-muted sort" data-sort="orders-nis">
                                                    FullName
                                                </a>
                                            </th>
                                            <th class="w-100">
                                                <a href="#" class="text-muted sort" data-sort="orders-name">
                                                    Username
                                                </a>
                                            </th>
                                            <th>
                                                <a href="#" class="text-muted sort" data-sort="orders-role">
                                                    Email
                                                </a>
                                            </th>
                                            <th>
                                                <a href="#" class="text-muted sort" data-sort="orders-role">
                                                    Roles
                                                </a>
                                            </th>
                                            <th>
                                                <a href="#" class="text-muted sort" data-sort="orders-role">
                                                    Last login
                                                </a>
                                            </th>
                                            <th>
                                                <a href="#" class="text-muted sort" data-sort="orders-status">
                                                    Action
                                                </a>
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody v-if="fetching" class="list">
                                        <tr>
                                            <td colspan="7">
                                                <div class="alert alert-primary" role="alert">
                                                    Sedang mengambil data..
                                                </div>
                                            </td>
                                        </tr>
                                    </tbody>
                                    <tbody v-if="!fetching">
                                        <tr v-for="(users, index) in dataUsers" :key="index">
                                            <td>{{ index+1 }}</td>
                                            <td>{{ users.fullname }}</td>
                                            <td>{{ users.username }}</td>
                                            <td>{{ users.email }}</td>
                                            <td>{{ users.roles }}</td>
                                            <td>{{ users.last_login }}</td>
                                            <td class="text-center">
                                                <button class="btn btn-info btn-sm mr-2"><i class="fe fe-edit mr-2"></i>Edit</button>
                                                <button @click.prevent="postDelete(users.id)" class="btn btn-danger btn-sm mr-2"><i class="fe fe-trash-2 mr-2"></i>Delete</button>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- END MAIN CONTENT -->
    </div>
</template>

<script>
    export default {
        data() {
            return {
                baseUrl: this.$apiconfig,
                dataUsers: [],
                fetching: true,
                form: {
                    fullname: '',
                    username: '',
                    password: '',
                    roles: '',
                }
            }
        },
        metaInfo: {
            title: 'Users',
        },
        created() {
            this.getData();
        },
        methods: {
            getData() {
                this.fetching = true;
                this.$http.get(this.baseUrl + 'admin/users')
                    .then((response) => {
                        //assign state formulir with response data
                        this.dataUsers = response.data.data;
                        this.fetching = false;
                    })
                    .catch((error) => {
                        console.log(error.response.data);
                        this.fetching = false;
                    });
            },
            submitForm() {
                this.$http.post(this.baseUrl + 'admin/users/register', this.form)
                    .then(() => {
                        this.$swal({
                            icon: 'success',
                            title: "success",
                            text: 'Create Data account Success',
                            showConfirmButton: false,
                            timer: 1500
                        })
                        this.getData();
                    })
                    .catch(error => {
                        console.log(error.response.data)
                    }).finally(() => {
                        //Perform action in always
                    });
            },
            postDelete(id) {
                var fd = new FormData()
                fd.append('id', id)
                this.$http({
                    url: this.baseUrl + 'admin/users/delete',
                    method: 'post',
                    data: fd
                }).then(() => {
                    this.getData();
                }).catch(error => {
                    console.log(error.response.data)
                })
            },
        },
    };
</script>